import React, {useContext, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import { GiSave, GiCancel } from "react-icons/gi";
import { BsPencilSquare } from "react-icons/bs";
import { Bounce, toast } from "react-toastify";

import { DisplayNameRequest } from "../goveepb/proto/govee_pb"
import ExpandedDeviceChartDialog from "./ExpandedDeviceChartDialog";
import BatteryMeter from "./BatteryMeter";
import {AuthContext} from "../contexts/AuthContext";

import WifiIndicator, {
  DBMToSignalStrength,
  SignalStrength
} from 'react-wifi-indicator';
import moment from "moment/moment";
import {StatusCode} from "grpc-web";

const signalStrengthOffset = 5;

const DeviceHeaderLabels = ({client, hasData, lastItem, device}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState(device.getDisplayName());
  const { fetchUser, getAccessToken } = useContext(AuthContext);

  const onLabelClick = (e) => {
    setIsEditing(!isEditing);
    setDisplayName(device.getDisplayName());
  };

  const updateDisplayName = (isLastTry) => {
    const accessToken = getAccessToken();
    const metadata = {"Authorization": "Bearer " + accessToken}
    if (!accessToken) {
      return;
    }

    const request = new DisplayNameRequest();
    request.setDeviceId(device.getId());
    request.setDisplayName(displayName);
    console.log("Updating displayName of device", device.getId(), "to", displayName);
    client.updateDeviceDisplayName(request, metadata, (err, response) => {
      if (err) {
        if (err.code === StatusCode.UNAUTHENTICATED) {
          if (!isLastTry) {
            console.error("Received auth error updating display name, attempting to refresh auth and retry", err)
            fetchUser().then(function () {
              console.log("Refresh attempt complete, retrying update display name")
              updateDisplayName(true);
            });
            return;
          }
        }

        console.error("Device update failed", err, response);
        toast.error('An error occurred updating display name: "' + err.message + '"', {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      } else {
        setDisplayName(displayName)
        device.setDisplayName(displayName);
        toast.info('Display name updated', {
          position: "bottom-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          transition: Bounce,
        });
      }
      setIsEditing(!isEditing);
    });
  }

  const handleSubmit = (e) => {
    // prevents the default browser behavior of submitting the form and refreshing the page.
    e.preventDefault();

    updateDisplayName();
  };

  let deviceDisplayName;
  if (isEditing) {
    deviceDisplayName =
        <div className="device-header-labels-display-name">
          <input type="text"
                 value={displayName}
                 onChange={(e) => setDisplayName(e.target.value)}
                 maxLength={100}
                 placeholder="Name"/>
          <GiSave className="device-header-labels-display-name-form" title="Update device display name" size="18px" onClick={handleSubmit}/>
          <GiCancel className="device-header-labels-display-name-form" title="Cancel" size="18px" onClick={onLabelClick}/>
        </div>
  } else {
    deviceDisplayName =
        <h3 className="device-header-labels-name"
            title={device.getId()}>
          <a onClick={onLabelClick}>{device.getDisplayName()}</a>
          <BsPencilSquare className="device-header-labels-display-name-edit" title="Edit device display name" size="14px" onClick={onLabelClick}/>
      </h3>
  }

  return (
      <div className="device-header-labels-container">
        <div className="device-header-labels">
          {deviceDisplayName}
          {hasData &&
              <>
                <h4 className="device-header-labels-byline device-header-labels-temperature">
                  Temp: {(Math.round(lastItem.getTemperature() * 10)
                    / 10).toFixed(1)}°F,
                </h4>
                <h4 className="device-header-labels-byline device-header-labels-humidity">
                  Humid: {(Math.round(lastItem.getHumidity() * 10)
                    / 10).toFixed(1)}%
                </h4>
              </>
          }
        </div>
      </div>
  );
};

const DeviceHeaderBluetoothSignalIndicator = ({client, hasData, lastItem, device, showSignalLabel, showExpand, onDragZoom}) => {
  const strength = device && DBMToSignalStrength(
          device.getBestSignalStrength() + signalStrengthOffset)
      || SignalStrength.DISCONNECTED

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1124px)'
  });

  const shouldShowExpand = isDesktopOrLaptop && showExpand;

  // Assemble signal alt title text
  var signalTitles = [];
  const tenMinutesAgo = Date.now()-1000*60*10;
  device.getSignalsList().filter((s) => s.getLastUpdated() >= tenMinutesAgo).forEach((s) => {
    signalTitles.push(s.getClientHostname() + ": " + s.getSignalStrength());
  });
  const signalTitle = signalTitles.join("\n");

  return (
      <div className="device-wifi-indicator-container">
        {hasData &&
            <>
              {showSignalLabel && <div className="device-wifi-indicator-label"
                   title={signalTitle}>Signal: {strength}</div>}
              <WifiIndicator alt={strength} strength={strength} style={{
                "margin": "0 5px 0 5px",
                "filter": "hue-rotate(20deg)",
                "height": 16,
                "float": "right",
              }}/>
              <div className="device-wifi-indicator-time">
                {moment(lastItem.getTime()).format('h:mm a')}
              </div>
              {shouldShowExpand && <ExpandedDeviceChartDialog client={client} device={device} onDragZoom={onDragZoom}/>}
            </>
        }
      </div>
  )
}

const DeviceChartHeader = ({client, device, data, showSignalLabel, showExpand, onDragZoom}) => {
  const hasData = data.length > 0;
  const lastItem = hasData && data[data.length - 1] || {};

  return (
      <div className="device-header">
        <DeviceHeaderLabels client={client} hasData={hasData} lastItem={lastItem} device={device}/>
        <BatteryMeter battery={device.getBattery()} numLevels="5"/>
        <DeviceHeaderBluetoothSignalIndicator client={client} hasData={hasData} lastItem={lastItem} device={device} showSignalLabel={showSignalLabel} showExpand={showExpand} onDragZoom={onDragZoom}/>
      </div>
  );

};

export default DeviceChartHeader
