import React, {useState, useEffect, useContext} from 'react';
import {StatusCode} from 'grpc-web';
import PulseLoader from "react-spinners/PulseLoader";

import {TimeSeriesRequest} from "../goveepb/proto/govee_pb"
//import {scaleLinear} from "d3-scale";
import {
  CartesianGrid, ReferenceArea,
  ResponsiveContainer, Tooltip,
  XAxis, YAxis, LineChart, Line
} from "recharts";
import moment from "moment/moment";
import DeviceChartHeader from "./DeviceChartHeader";
import {AuthContext} from "../contexts/AuthContext";
import {ZoomContext} from "../contexts/ZoomContext";

const tickLookup = [3600*1000*24*182, 3600*1000*24*63, 3600*1000*24*30, 3600*1000*24*14, 3600*1000*24*7, 3600*1000*24*2, 3600*1000*24, 3600*1000*12, 3600*1000*8, 3600*1000*6, 3600*1000*4, 3600*1000*3, 3600*1000*2, 60*60*1000, 45*60*1000, 30*60*1000, 20*60*1000, 10*60*1000, 5*60*1000, 2*60*1000, 60*1000, 40*1000, 30*1000, 20*1000, 10*1000, 5*1000, 4*1000, 2*1000, 1000, 500, 200, 100, 50];

const DeviceChart = ({client, device, onDragZoom, style, targetPoints, showSignalLabel, showExpand}) => {
  const [data, setData] = useState([]);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [ticks, setTicks] = useState([]);
  const [ticksTemperature, setTicksTemperature] = useState([]);
  const [ticksHumidity, setTicksHumidity] = useState([]);
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const { getAccessToken } = useContext(AuthContext);
  const { since, until } = useContext(ZoomContext);
  const zoomIntervalMillis = until - since;
  console.log("Creating DeviceChart, device", device.toObject(), zoomIntervalMillis, new Date(since),"<->", new Date(until));

  const getTemp = () => {
    const accessToken = getAccessToken();
    const metadata = {"Authorization": "Bearer " + accessToken}
    if (!accessToken) {
      return;
    }
    
    console.log("Fetching timeseries temperature data for", device.toObject(), new Date(since), "<->", new Date(until), "with token", accessToken);
    const tsRequest = new TimeSeriesRequest([device.getId(), since, until, targetPoints || 250])

    client.getSamplesWithMetadata(tsRequest, metadata, (err, response) => {
      if (err) {
        setIsLoadingChart(false);
        if (err.code === StatusCode.UNAUTHENTICATED) {
          // Don't bother retrying and having burst of retry attempts.
          console.error("Received auth error getting measurements. Don't bother retrying, let device list refresh to the retry", err)
          return;
        }

        // No toast error message this level because it might be a burst
        console.error("Received error getting batch of measurements", err);
      } else {
        const samples = response.getSamplesList()
        console.log("Received", samples.length, "measurements for device", device.getId());
        const [linearDomainMin, linearDomainMax] = [tsRequest.getSince(), tsRequest.getUntil()];
        // const linearScale = scaleLinear().domain([linearDomainMin, linearDomainMax]);
        console.log("linear domain min/max", linearDomainMin, linearDomainMax);
        const durationMillis = linearDomainMax - linearDomainMin;
        const tickInterval = durationMillis / 6;
        console.log("tick Interval", tickInterval);

        var modInterval = tickLookup.find((e) => tickInterval / 8 >= e);
        var selectedInterval = tickLookup.find((e) => tickInterval >= e);
        console.log("selected interval", selectedInterval, "modInterval", modInterval);

        var start = linearDomainMin - (linearDomainMin % modInterval);
        var end = linearDomainMax;
        var tickValues = [];
        var i = start;
        for (i = start; i < end; i += selectedInterval) {
          tickValues.push(i);
        }

        let [minT, maxT] = [60, 70];
        let [minH, maxH] = [30, 60];
        if (samples.length > 0) {
          const seriesMetadata = response.getMetadataMap()

          const temperatureMetadata = seriesMetadata.get("temperature");
          [minT, maxT] = [Math.min(minT, temperatureMetadata.getSeriesMin()), Math.max(maxT, temperatureMetadata.getSeriesMax())];
          //console.log("SeriesMetadata temperature", seriesMetadata, temperatureMetadata, minT, maxT, "avg", temperatureMetadata.getSeriesAvg());

          const humidityMetadata = seriesMetadata.get("humidity");
          [minH, maxH] = [Math.min(minH, humidityMetadata.getSeriesMin()), Math.max(maxH, humidityMetadata.getSeriesMax())];
          //console.log("SeriesMetadata humidity", seriesMetadata, humidityMetadata, minH, maxH, "avg", humidityMetadata.getSeriesAvg());
        }

        var tickTemperatureValues = [];
        start = minT - (minT % 10);
        end = (maxT + 10) - (maxT % 10);
        for (i = start; i <= end; i += 4) {
          tickTemperatureValues.push(i);
        }

        var tickHumidityValues = [];
        start = minH - (minH % 10);
        end = (maxH + 10) - (maxH % 10);
        for (i = start; i <= end; i += 5) {
          tickHumidityValues.push(i);
        }

        //console.log("tickValues", tickValues, "tickTemperatureValues", tickTemperatureValues, "tickHumidityValues", tickHumidityValues);
        setTicks(tickValues);
        setTicksTemperature(tickTemperatureValues);
        setTicksHumidity(tickHumidityValues);

        // console.log(linearScale.ticks(12))
        setData(samples);
        setIsLoadingChart(false);
      }
    });
  };

  const zoom = () => {
    let left = refAreaLeft;
    let right = refAreaRight;
    console.log("zoom refAreaLeft", left, "refAreaRight", right);

    // Check if click but didn't drag
    if (left === right || right === '' || !left || !right) {
      setRefAreaLeft('');
      setRefAreaRight('');
      return;
    }

    // xAxis domain
    if (left > right) {
      // user dragged from right to left, swap around so left is always less than right
      [left, right] = [right, left];
    }

    // Reset to hide drag highlight now that mouse up has happened
    setRefAreaLeft('');
    setRefAreaRight('');

    console.log("New since and until", left, right, new Date(left), new Date(right));
    onDragZoom(left, right);
  }

  useEffect(()=>{
    getTemp()
  },[since, until]);

  const formattedData = data.map((o) => ({
    "timestamp": o.getTime(),
    "temperature": o.getTemperature(),
    "humidity": o.getHumidity()
  }));

  const tickXFormatter = (unixTime) => {
    if (zoomIntervalMillis >= 604800000) { // 1 week
      return moment(unixTime).format('M/DD')
    } else if (zoomIntervalMillis >= 129600000) { // 36 hours
      return moment(unixTime).format('MM/DD hh:mma');
    } else if (zoomIntervalMillis >= 360000) { // 6 minutes
      return moment(unixTime).format('hh:mma');
    } else {
      return moment(unixTime).format('hh:mm:ssa');
    }
  }

  const tickYTemperatureFormatter = (value) => value.toFixed(0);

  const tickYHumidityFormatter = (value) => value.toFixed(0);

  const tooltipValuesFormatter = (value, name) => (name === "Time")
      ? moment(value).format('MMM DD, h:mm:ss a')
      : (Math.round(value * 10) / 10).toFixed(1);

  const tooltipDateFormatter = (label, payload) => (label)
      ? moment(label).format('MMM DD, h:mm:ss a')
      : label;

  if (isLoadingChart) {
    return <>
      <DeviceChartHeader client={client} device={device} data={data} showSignalLabel={showSignalLabel} showExpand={showExpand} onDragZoom={onDragZoom}/>
      <div className="device-chart" style={style}>
        <ResponsiveContainer width='100%' height='100%' style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center'}}>
          <PulseLoader speedMultiplier={0.8} color="#629af0"/>
        </ResponsiveContainer>
      </div>
    </>
  }

  return (
      <>
        <DeviceChartHeader client={client} device={device} data={data} showSignalLabel={showSignalLabel} showExpand={showExpand} onDragZoom={onDragZoom}/>
        <div className="device-chart" style={style}>
          <ResponsiveContainer width='100%' height='100%'>
            <LineChart data={formattedData}
                       onMouseDown={(e) => e && setRefAreaLeft(e.activeLabel)}
                       onMouseMove={(e) => e && refAreaLeft && setRefAreaRight(e.activeLabel)}
                       onMouseUp={(e) => zoom()}
                       margin={{
              top: 10,
              right: 10,
              bottom: 10,
              left: 0,
            }}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis
                  dataKey='timestamp'
                  domain={([dataMin, dataMax]) => { const offset = zoomIntervalMillis / 50; return [dataMin - offset, dataMax + offset]; }}
                  name='Time'
                  ticks={ticks}
                  tickFormatter={tickXFormatter}
                  type='number'
              />
              <YAxis yAxisId="0" orientation="left"
                     ticks={ticksTemperature}
                     tickFormatter={tickYTemperatureFormatter}
                     name='Temp (F)' domain={[60, 80]} label={{
                value: 'Temp (F)',
                angle: -90,
                position: "insideLeft",
                offset: 20
              }} unit="F"/>
              <YAxis yAxisId="1" orientation="right"
                     ticks={ticksHumidity}
                     tickFormatter={tickYHumidityFormatter}
                     name='Humidity (%)' domain={[30, 70]} label={{
                value: 'Humidity (%)',
                angle: -90,
                position: "insideTopRight",
                offset: 22
              }} unit="%"/>

              <Line
                  data={formattedData}
                  dataKey='temperature'
                  animationDuration={500}
                  stroke="#ff780a"
                  strokeWidth={2}
                  dot={false}
                  yAxisId="0"
                  name='Temp'
                  type="stepAfter"
                  unit="°F"
              />
              <Line
                  data={formattedData}
                  dataKey='humidity'
                  animationDuration={500}
                  stroke="#629af0"
                  strokeWidth={2}
                  dot={false}
                  yAxisId="1"
                  name='Humidity'
                  type="stepAfter"
                  unit="%"
              />

              {refAreaLeft && refAreaRight ? (
                  <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
              ) : null}

              <Tooltip
                  offset={30}
                  formatter={tooltipValuesFormatter}
                  labelFormatter={tooltipDateFormatter}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </>
  );
};

export default DeviceChart;

